import { Resources } from '@/tt-entity-design/src/types'
import { TTC_API_MAX_LIMIT } from '@/tt-widget-components/constants'
import { FilterOperatorType } from '@/tt-widget-factory'
import { SortDirectionType } from 'tracktik-sdk/lib/common/entity-collection'

const queryCategoryFields = [
  {
    attribute: 'name',
  },
  {
    attribute: 'iconClass',
  },
  {
    attribute: 'parentCategory',
  },
  {
    attribute: 'options',
  },
  {
    attribute: 'id',
    alias: 'meta.id',
  },
  {
    attribute: 'aclRule',
    alias: 'meta.aclRule',
  },
  {
    attribute: 'ownedBy.avatar',
    alias: 'meta.ownedBy.avatar',
  },
  {
    attribute: 'ownedBy.name',
    alias: 'meta.ownedBy.name',
  },
  {
    attribute: 'ownedBy.id',
    alias: 'meta.ownedBy.id',
  },
  {
    attribute: 'updatedOn',
    alias: 'meta.updatedOn',
  },
]

export const queryWidgetFields = [
  {
    attribute: 'id',
    alias: 'meta.id',
  },
  {
    attribute: 'uid',
  },
  {
    attribute: 'title',
  },
  {
    attribute: 'is',
  },
  {
    attribute: 'uri',
    alias: 'meta.uri',
  },
  {
    attribute: 'aclRule',
    alias: 'meta.aclRule',
  },
  {
    attribute: 'ownedBy.id',
    alias: 'meta.ownedBy.id',
  },
  {
    attribute: 'ownedBy.avatar',
    alias: 'meta.ownedBy.avatar',
  },
  {
    attribute: 'ownedBy.name',
    alias: 'meta.ownedBy.name',
  },
  {
    attribute: 'category',
  },
  {
    attribute: 'configs',
  },
  {
    attribute: 'createdBy',
    alias: 'meta.createdBy',
  },
  {
    attribute: 'createdOn',
    alias: 'meta.createdOn',
  },
  {
    attribute: 'updatedOn',
    alias: 'meta.updatedOn',
  },
]

export const widgetQuery = {
  resource: Resources.DATA_VIEWS,
  fields: queryWidgetFields,
  limit: TTC_API_MAX_LIMIT,
}

export const categoryQuery = {
  resource: Resources.DATA_VIEW_CATEGORIES,
  fields: queryCategoryFields,
  limit: TTC_API_MAX_LIMIT,
}

export const getInactiveQuery = (userId: number) => {
  return {
    resource: Resources.DATA_VIEWS,
    fields: queryWidgetFields,
    filters: [
      {
        attribute: 'status',
        operator: FilterOperatorType.EQUAL,
        value: 'ARCHIVED',
      },
      {
        attribute: 'includeInactive',
        operator: FilterOperatorType.EQUAL,
        value: true,
      },
      {
        attribute: 'ownedBy',
        operator: FilterOperatorType.EQUAL,
        value: userId,
      },
    ],
    sort: [
      {
        attribute: 'updatedOn',
        direction: SortDirectionType.ASC,
      },
    ],
    limit: 1000,
  }
}
