import {
  EntityCollectionRequestOptions,
  EntityCollectionResponse,
} from 'tracktik-sdk/lib/common/entity-collection'

import ResourcePermissionAuditor, {
  getResourcePermissionAuditorServices,
} from '@/tt-widget-factory/services/resource-meta/ResourcePermissionAuditor'
import { AppContext } from '@/tt-app-context'
import {
  CollectionQueryResponse,
  WidgetStoreInterface,
} from '@/tt-widget-factory'
import { Resources } from '@/tt-entity-design/src/types'
import { WidgetCollectionSearchProviderInterface } from '@/tt-widget-factory/services/widget-collections/types'
import { WidgetName } from '@/tt-widget-components/lib/names'

import { DataViewProviderInterface, PageSettings } from './types'
import { getCustomWidgetQuery } from './data-view-query-builder'
import {
  getInactiveQuery,
  getWidgetQueryByCategoryId,
  getWidgetQueryByWidgetType,
  getWidgetQueryByUid,
} from './data-view-provider-query'
import {
  toWidgetArchiveModel,
  toWidgetStoreModel,
} from './normalize-data-view-response'

export const CUSTOM_WIDGET_PROVIDER_NAME = 'my-insights'

export default class DataViewProvider implements DataViewProviderInterface {
  appContext: AppContext

  /**
   * App context
   * @param appContext
   */
  constructor(appContext: AppContext) {
    this.appContext = appContext
  }

  private userId(): number {
    return this.appContext.authModule.getUserId()
  }

  /**
   *  Fetch views
   *  @param searchProvider
   */
  async fetchViews(
    searchProvider: WidgetCollectionSearchProviderInterface,
  ): Promise<WidgetStoreInterface[]> {
    // No permission to view
    if (
      !ResourcePermissionAuditor.canViewResource(
        getResourcePermissionAuditorServices(this.appContext),
        Resources.DATA_VIEWS,
      )
    ) {
      return []
    }
    const { resource, ...options } = getCustomWidgetQuery(
      searchProvider,
      this.userId(),
    )

    const response: EntityCollectionResponse<CollectionQueryResponse> =
      await this.appContext.authModule
        .getApi()
        .getAll(resource, options as EntityCollectionRequestOptions)

    return response.items.map((dataView: any) =>
      toWidgetStoreModel(dataView, { myId: this.userId() }),
    )
  }

  /**
   *  Fetch inactive views
   *  @param pageSettings
   */
  async fetchArchiveViews(
    pageSettings: PageSettings,
  ): Promise<WidgetStoreInterface[]> {
    // No permission to view
    if (
      !ResourcePermissionAuditor.canViewResource(
        getResourcePermissionAuditorServices(this.appContext),
        Resources.DATA_VIEWS,
      )
    ) {
      return []
    }

    const { resource, ...options } = getInactiveQuery(
      this.userId(),
      pageSettings,
    )

    const response: EntityCollectionResponse<CollectionQueryResponse> =
      await this.appContext.authModule
        .getApi()
        .getAll(resource, options as EntityCollectionRequestOptions)

    return response.items.map((dataView: any) =>
      toWidgetArchiveModel(dataView, { myId: this.userId() }),
    )
  }

  /**
   *  Fetch views by category id
   *  @param catergoryId
   */
  async fetchWidgetByCategoryId(
    catergoryId: string,
  ): Promise<WidgetStoreInterface[]> {
    const { resource, ...options } = getWidgetQueryByCategoryId(catergoryId)

    const response: EntityCollectionResponse<CollectionQueryResponse> =
      await this.appContext.authModule
        .getApi()
        .getAll(resource, options as EntityCollectionRequestOptions)

    return response.items.map((dataView: any) =>
      toWidgetStoreModel(dataView, { myId: this.userId() }),
    )
  }

  /**
   *  Fetch views by widget type
   *  @param widgetType
   */
  async fetchWidgetByWidgetType(
    widgetType: WidgetName,
  ): Promise<WidgetStoreInterface[]> {
    const { resource, ...options } = getWidgetQueryByWidgetType(widgetType)

    const response: EntityCollectionResponse<CollectionQueryResponse> =
      await this.appContext.authModule
        .getApi()
        .getAll(resource, options as EntityCollectionRequestOptions)

    return response.items.map((dataView: any) =>
      toWidgetStoreModel(dataView, { myId: this.userId() }),
    )
  }

  /**
   *  Fetch views by uid
   *  @param uid
   */
  async fetchWidgetByUid(uid: string): Promise<WidgetStoreInterface> {
    const { resource, ...options } = getWidgetQueryByUid(uid)

    const response: EntityCollectionResponse<CollectionQueryResponse> =
      await this.appContext.authModule
        .getApi()
        .getAll(resource, options as EntityCollectionRequestOptions)

    const formattedResponse = response.items.map((dataView: any) => {
      return toWidgetStoreModel(dataView, { myId: this.userId() })
    })

    return formattedResponse[0]
  }
}
