<template>
  <v-list-item class="navigation-item px-0" @click="showAlert">
    <v-tooltip top :open-delay="tooltipOpenDelay">
      <template #activator="{ on }">
        <div class="d-flex flex-grow-1 px-4" v-on="on">
          <v-list-item-icon class="mr-4">
            <v-icon notranslate class="theme--dark grey--text" size="20">
              mdi-comment-quote
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              class="font-weight-light"
              v-text="$t('extension_button.feedback')"
            />
          </v-list-item-content>
        </div>
      </template>
      <span v-text="$t('extension_button.feedback')" />
    </v-tooltip>
  </v-list-item>
</template>

<script lang="ts">
import Vue from 'vue'
import { TOOLTIP_OPEN_DELAY } from '@/helpers/constants'

export default Vue.extend({
  computed: {
    tooltipOpenDelay(): number {
      return TOOLTIP_OPEN_DELAY
    },
  },
  methods: {
    async showAlert() {
      const issueCollector = await this.$issueCollector()
      issueCollector.show()
    },
  },
})
</script>
