import { AppContext } from '@/tt-app-context'
import { EventProviderInterface } from '@/tt-widget-entity-flow/types'

import WidgetPersister from '@/apps/app.tracktik.insights.studio/lib/WidgetPersister'

export default abstract class WidgetBaseIntent
  implements EventProviderInterface
{
  abstract getEventName(): string

  abstract run(payload: any): any

  appContext: AppContext
  widgetPersister: WidgetPersister

  constructor(appContext: AppContext, widgetPersister: WidgetPersister) {
    this.appContext = appContext
    this.widgetPersister = widgetPersister
  }
}
