import { WidgetName } from '@/tt-widget-components/lib/names'
import { WidgetStoreInterface } from '@/tt-widget-factory'
import { WidgetCollectionSearchProviderInterface } from '@/tt-widget-factory/services/widget-collections/types'

export interface PageSettings {
  limit: number
  offset: number
}

export enum GrantSource {
  This = 'THIS',
  Parent = 'PARENT',
}

export enum Status {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Archived = 'ARCHIVED',
}

export enum DataViewsAttributes {
  is = 'is',
  widgetType = 'widgetType',
  name = 'name',
  title = 'title',
  status = 'status',
  description = 'description',
  uid = 'uid',
  category = 'category',
  configs = 'configs',
  ownedBy = 'ownedBy',
  archivedBy = 'archivedBy',
  aclRule = 'aclRule',
  grantSource = 'grantSource',
  id = 'id',
  uri = 'uri',
  includeInactive = 'includeInactive',
  resourceType = 'resourceType',
  createdBy = 'createdBy',
  createdOn = 'createdOn',
  updatedBy = 'updatedBy',
  updatedOn = 'updatedOn',
}

export interface DataViewProviderInterface {
  fetchArchiveViews: (page: {
    limit: number
    offset: number
  }) => Promise<WidgetStoreInterface[]>

  fetchViews: (
    searchProvider: WidgetCollectionSearchProviderInterface,
  ) => Promise<WidgetStoreInterface[]>

  fetchWidgetByCategoryId: (
    categoryId: string,
  ) => Promise<WidgetStoreInterface[]>

  fetchWidgetByWidgetType: (
    widgetType: WidgetName,
  ) => Promise<WidgetStoreInterface[]>

  fetchWidgetByUid: (uid: string) => Promise<WidgetStoreInterface>
}
