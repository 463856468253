import { SortDirectionType } from 'tracktik-sdk/lib/common/entity-collection'

import { CollectionQuery } from '@/tt-widget-components'
import { Field, FilterOperatorType } from '@/tt-widget-factory'
import { Resources } from '@/tt-entity-design/src/types'

import { DataViewsAttributes, Status } from './types'

export const getQueryWidgetFields = (): Field[] => [
  {
    attribute: 'id',
    alias: 'meta.id',
  },
  {
    attribute: 'uid',
  },
  {
    attribute: 'title',
  },
  {
    attribute: 'is',
  },
  {
    attribute: 'uri',
    alias: 'meta.uri',
  },
  {
    attribute: 'aclRule',
    alias: 'meta.aclRule',
  },
  {
    attribute: 'ownedBy.id',
    alias: 'meta.ownedBy.id',
  },
  {
    attribute: 'ownedBy.avatar',
    alias: 'meta.ownedBy.avatar',
  },
  {
    attribute: 'ownedBy.name',
    alias: 'meta.ownedBy.name',
  },
  {
    attribute: 'category',
  },
  {
    attribute: 'configs',
  },
  {
    attribute: 'createdBy',
    alias: 'meta.createdBy',
  },
  {
    attribute: 'createdOn',
    alias: 'meta.createdOn',
  },
  {
    attribute: 'updatedOn',
    alias: 'meta.updatedOn',
  },
]

export const getInactiveQuery = (
  userId,
  { offset, limit },
): CollectionQuery => ({
  resource: Resources.DATA_VIEWS,
  fields: getQueryWidgetFields(),
  filters: [
    {
      attribute: DataViewsAttributes.status,
      operator: FilterOperatorType.EQUAL,
      value: Status.Archived,
    },
    {
      attribute: DataViewsAttributes.includeInactive,
      operator: FilterOperatorType.EQUAL,
      value: true,
    },
    {
      attribute: DataViewsAttributes.ownedBy,
      operator: FilterOperatorType.EQUAL,
      value: userId,
    },
  ],
  sort: [
    {
      attribute: DataViewsAttributes.updatedOn,
      direction: SortDirectionType.DESC,
    },
  ],
  limit,
  offset,
})

export const getWidgetQueryByCategoryId = (
  catergoryId: string,
): CollectionQuery => ({
  resource: Resources.DATA_VIEWS,
  fields: getQueryWidgetFields(),
  filters: [
    {
      attribute: DataViewsAttributes.category,
      operator: FilterOperatorType.EQUAL,
      value: catergoryId,
    },
  ],
})

export const getWidgetQueryByWidgetType = (
  widgetType: string,
): CollectionQuery => ({
  resource: Resources.DATA_VIEWS,
  fields: getQueryWidgetFields(),
  filters: [
    {
      attribute: DataViewsAttributes.widgetType,
      operator: FilterOperatorType.EQUAL,
      value: widgetType,
    },
  ],
})

export const getWidgetQueryByUid = (uid: string): CollectionQuery => ({
  resource: Resources.DATA_VIEWS,
  fields: getQueryWidgetFields(),
  filters: [
    {
      attribute: DataViewsAttributes.uid,
      operator: FilterOperatorType.EQUAL,
      value: uid,
    },
  ],
})
