import uuid from 'uuid/v1'

import { EntityCollectionRequestOptions } from 'tracktik-sdk/lib/common/entity-collection'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'

import { AclRule } from '@/tt-widget-sharable'
import { AppContext } from '@/tt-app-context'
import { CollectionQuery, Filter } from '@/tt-widget-components'
import {
  CollectionQueryResponse,
  WidgetArchiveModel,
  WidgetCategoryInterface,
  WidgetCategoryModel,
  WidgetReference,
  WidgetStoreInterface,
  WidgetStoreModel,
} from '@/tt-widget-factory'
import { Resources } from '@/tt-entity-design/src/types'
import { WidgetCollectionPersisterInterface } from '@/tt-widget-factory/services/widget-collections/types'
import { WidgetCategoryPayload } from '@/tt-widget-factory/WidgetCategoryModel'

import { categoryQuery, widgetQuery } from './CustomWidgetProviderQuery'
import { DataViewResource } from './CustomWidgetProvider'
import {
  fixAclRule,
  toCategoryModel,
  toWidgetStoreModel,
} from './DataViewProvider/normalize-data-view-response'
import { getWidgetQueryByUid } from './DataViewProvider/data-view-provider-query'

const { DATA_VIEWS, DATA_VIEW_CATEGORIES } = Resources

export const trimCategory = (category: string) => {
  if (category.includes('|')) {
    return category.split('|')[1]
  }
  return category
}

/**
 * Widget persister
 */
export default class WidgetPersister
  implements WidgetCollectionPersisterInterface
{
  appContext: AppContext

  constructor(appContext: AppContext) {
    this.appContext = appContext
  }

  getCategoryByUid(categoryUid: string | number): Promise<WidgetCategoryModel> {
    return Promise.resolve(WidgetCategoryModel.find(categoryUid))
  }

  createCategory(category: WidgetCategoryPayload): Promise<void> {
    if (category.parentCategory) {
      category.parentCategory = trimCategory(category.parentCategory)
    }
    const reloadCategoryByID = ({ id }) => this.reloadCategoryByID(id)

    return this.appContext.authModule
      .getApi()
      .create<{ id }>(DATA_VIEW_CATEGORIES, category)
      .then(reloadCategoryByID)
  }

  updateCategory(categorySource: WidgetCategoryInterface): Promise<void> {
    const category = { ...categorySource }
    if (category.parentCategory) {
      category.parentCategory = trimCategory(category.parentCategory)
    }
    const { uid, ...payload } = category
    const reloadCategoryByID = ({ id }) => this.reloadCategoryByID(id)

    return this.appContext.authModule
      .getApi()
      .edit(DATA_VIEW_CATEGORIES, trimCategory(uid), payload)
      .then(reloadCategoryByID)
  }

  /**
   * Update the
   * @param category
   * @param to
   */
  moveCategory(
    category: WidgetCategoryInterface,
    to?: WidgetCategoryInterface,
  ): Promise<void> {
    const data = { ...category }
    data.parentCategory = to ? trimCategory(to.uid) : null
    return this.updateCategory(data)
  }

  static widgetToDataView(
    widget: WidgetReference,
    merge: any = {},
    categoryUid?: string,
  ): DataViewResource {
    // Apply some defaults
    const widgetToSave = { ...widget, ...merge }
    delete widgetToSave.meta
    delete widgetToSave.provider

    const category = categoryUid
      ? { category: trimCategory(categoryUid) }
      : null

    return {
      is: widgetToSave.is,
      uid: widgetToSave.uid,
      title: widgetToSave.title,
      subTitle: widgetToSave.subTitle,
      ...category,
      configs: JSON.stringify(widgetToSave),
    }
  }

  removeCategory(category: WidgetCategoryModel): Promise<void> {
    return this.appContext.authModule
      .getApi()
      .doAction(DATA_VIEW_CATEGORIES, trimCategory(category.uid), 'remove')
      .then(() => {
        WidgetCategoryModel.delete(category.uid)
      })
  }

  copy(widget: WidgetReference, category?: string): Promise<WidgetReference> {
    const widgetCopy = { ...widget, ...{ uid: uuid() } }
    return this.create(widgetCopy, category)
  }

  archive(widget: WidgetReference): Promise<WidgetReference> {
    return this.getDataViewByUid(widget.uid).then((response) => {
      return this.appContext.authModule
        .getApi()
        .doAction<any, any, DataViewResource>(
          DATA_VIEWS,
          response.id,
          'archive',
        )
        .then((response) => {
          WidgetArchiveModel.insert({ data: widget })
          WidgetStoreModel.delete(widget.uid)
          return response
        })
        .catch(() => WidgetStoreModel.delete(widget.uid))
    })
  }

  unarchive(
    widget: WidgetReference,
    restorePermissions: boolean,
  ): Promise<void> {
    return this.getInactiveDataViewByUid(widget.uid).then(({ id }) => {
      return this.appContext.authModule
        .getApi()
        .doAction<any, any, DataViewResource>(DATA_VIEWS, id, 'unarchive', {
          restorePermissions,
        })
        .then(async ({ id }) => {
          const refreshedWidget = await this.reloadByID(id)
          WidgetArchiveModel.delete(refreshedWidget[0].uid)
        })
    })
  }

  archiveCategory(category: WidgetCategoryInterface) {
    return this.appContext.authModule
      .getApi()
      .doAction(DATA_VIEW_CATEGORIES, trimCategory(category.uid), 'archive')
      .then((response) => {
        WidgetCategoryModel.delete(category.uid)
        return response
      })
      .catch(() => {
        // @todo Maybe not
        WidgetCategoryModel.delete(category.uid)
      })
  }

  /**
   * Move a widget to another
   * @param widget
   * @param category
   */
  moveTo(widget: WidgetReference, category: WidgetCategoryInterface) {
    const dataViewItem = WidgetPersister.widgetToDataView(
      widget,
      {},
      category.uid,
    )

    return this.getDataViewByUid(widget.uid).then(
      (serverDataView: DataViewResource) =>
        this.editDataViews(widget.uid, serverDataView.id, dataViewItem),
    )
  }

  getDataViewByUid(uid: string) {
    return this.appContext.authModule
      .getApi()
      .getAll<DataViewResource>(DATA_VIEWS, {
        filters: [
          {
            attribute: 'uid',
            operator: 'EQUAL',
            value: uid,
          },
        ],
      })
      .then((response) => {
        return response.items[0]
      })
  }

  getInactiveDataViewByUid(uid: string) {
    return this.appContext.authModule
      .getApi()
      .getAll<DataViewResource>(DATA_VIEWS, {
        filters: [
          {
            attribute: 'uid',
            operator: 'EQUAL',
            value: uid,
          },
          {
            attribute: 'includeInactive',
            operator: FilterOperatorType.EQUAL,
            value: true,
          },
        ],
      })
      .then((response) => {
        return response.items[0]
      })
  }

  save(widget: WidgetReference, merge?: any, category?: string) {
    const dataViewItem = WidgetPersister.widgetToDataView(
      widget,
      merge,
      category,
    )
    // API is validating that on a shared widget the category cannot be changed (API-1022).
    // We need to remove the category from the payload when saving
    const { category: _, ...payload } = dataViewItem

    // Data View
    return this.getDataViewByUid(widget.uid).then(
      (serverDataView: DataViewResource) => {
        // Does not exists
        if (!serverDataView) {
          return this.create(widget, category)
        }

        // Update the current one
        return this.editDataViews(widget.uid, serverDataView.id, payload)
      },
    )
  }

  createWidget(widgetModel: WidgetStoreInterface): Promise<WidgetReference> {
    const { widget, category } = widgetModel
    if (!widget.title) {
      widget.title = 'Untitled widget'
    }

    const viewData = WidgetPersister.widgetToDataView(
      widget,
      {
        uid: widget.uid ? widget.uid : uuid(),
      },
      category,
    )
    return this.appContext.authModule
      .getApi()
      .create<DataViewResource>(DATA_VIEWS, viewData)
      .then((response: DataViewResource) => {
        return this.reloadByUID(response.uid).then(() => response)
      })
  }

  editWidget(widgetModel: WidgetStoreInterface): Promise<WidgetStoreInterface> {
    const { widget, meta, category } = widgetModel

    const dataViewItem = WidgetPersister.widgetToDataView(widget, {}, category)

    // API is validating that on a shared widget the category cannot be changed (API-1022).
    // We need to remove the category from the payload when saving
    const { category: _, ...payload } = dataViewItem
    return this.appContext.authModule
      .getApi()
      .edit<any, DataViewResource>(DATA_VIEWS, meta.id, payload)
      .then((response: DataViewResource) => {
        // TODO should not be needed after FE-555, we still have to refresh the store after updating data
        return this.reloadByUID(response.uid).then(() => response)
      })
      .then((response: DataViewResource) => {
        // We need to fetch again the widget because we don't have aclRules in edit response
        return this.fetchWidgetByUid(response.uid)
      })
  }

  // TODO reorganize on FE-554
  fetchWidgetByUid(uid: string): Promise<WidgetStoreInterface> {
    const myId = this.appContext.authModule.getUserId()

    const { resource, ...options } = getWidgetQueryByUid(uid)

    return this.appContext.authModule
      .getApi()
      .getAll(resource, options as EntityCollectionRequestOptions)
      .then((response) => {
        const formattedResponse = response.items.map((dataView: any) => {
          return toWidgetStoreModel(dataView, { myId })
        })
        return formattedResponse[0]
      })
  }

  editDataViews(
    widgetUid: string,
    dataViewId: number,
    dataView: DataViewResource,
  ): Promise<DataViewResource> {
    return this.appContext.authModule
      .getApi()
      .edit<any, DataViewResource>(DATA_VIEWS, dataViewId, dataView)
      .then((response: DataViewResource) => {
        // TODO should not be needed
        return this.reloadByUID(widgetUid).then(() => response)
      })
  }

  /**
   * Reload
   * @param filters
   */
  reloadCategoryByFilters(filters: Filter[] = []): Promise<void> {
    const myId = this.appContext.authModule.getUserId()

    const convertToCategoryModel = (category: any) =>
      toCategoryModel(category, { myId })

    const convertResponse = (response: CollectionQueryResponse) =>
      response.items.map(convertToCategoryModel)

    const updateCategoryStore = (data) =>
      WidgetCategoryModel.insertOrUpdate({ data })

    const updateAllCategoriesInStore = async (
      models: WidgetCategoryModel[],
    ) => {
      const modelsUpdater = models.map(updateCategoryStore)
      await Promise.all(modelsUpdater)
    }

    const query: CollectionQuery = {
      ...categoryQuery,
      filters,
    }

    return this.appContext.widgetServices.resourceDataManager
      .getCollection(query, { disableCache: true })
      .then(convertResponse)
      .then(updateAllCategoriesInStore)
  }

  /**
   * Reload
   * @param filters
   */
  reloadByFilters(filters: Filter[] = []): Promise<WidgetStoreModel[]> {
    // Prepare the query
    const query = {
      ...widgetQuery,
      filters,
    } as CollectionQuery

    const myId = this.appContext.authModule.getUserId()

    // Fetch the data
    return this.appContext.widgetServices.resourceDataManager
      .getCollection(query, { disableCache: true })
      .then((response: CollectionQueryResponse) => {
        // Map to store widget
        return response.items.map((dataView: any) => {
          return toWidgetStoreModel(dataView, { myId })
        })
      })
      .then((models: WidgetStoreModel[]) => {
        models.forEach((model) => {
          WidgetStoreModel.insertOrUpdate({ data: model })
        })

        return models
      })
  }

  /**
   * Reload a widget from the server from its UID
   * @param uid
   */
  reloadByUID(uid: string): Promise<WidgetStoreModel[]> {
    return this.reloadByFilters([
      {
        attribute: 'uid',
        operator: 'EQUAL',
        value: uid,
      },
    ])
  }

  reloadByID(id: string): Promise<WidgetStoreModel[]> {
    return this.reloadByFilters([
      {
        attribute: 'id',
        operator: 'EQUAL',
        value: id,
      },
    ])
  }

  reloadCategoryByID(id: string): Promise<void> {
    return this.reloadCategoryByFilters([
      {
        attribute: 'id',
        operator: 'EQUAL',
        value: id,
      },
    ])
  }

  create(
    widget: WidgetReference,
    category?: string,
  ): Promise<DataViewResource> {
    if (!widget.title) {
      widget.title = 'Untitled widget'
    }

    const viewData = WidgetPersister.widgetToDataView(
      widget,
      {
        uid: widget.uid ? widget.uid : uuid(),
      },
      category,
    )

    return this.appContext.authModule
      .getApi()
      .create<DataViewResource>(DATA_VIEWS, viewData)
      .then((dataView) => {
        return this.reloadByUID(viewData.uid).then(() => dataView)
      })
  }

  // @todo: type 'response' from API data-views interface
  private updateWidgetAclRule(response: { uid; aclRule }): void {
    const aclRule = fixAclRule(response.aclRule)

    // response !== WidgetStoreModel (cf: CustomWidgetProvider) (INS-1325)
    const widget = WidgetStoreModel.find(response.uid)

    const data = {
      ...widget,
      meta: { ...widget.meta, aclRule },
    }

    WidgetStoreModel.insertOrUpdate({ data })
  }

  shareWidget(id: number, aclRule: AclRule): Promise<void> {
    return this.appContext.authModule
      .getApi()
      .doAction<number, { aclRule }, { id }>(DATA_VIEWS, id, 'share', {
        aclRule,
      })
      .then(async ({ id }) => {
        const refreshedWidget = await this.reloadByID(id)
        // we need to relay to the store to make SharableAvatars updated
        if (WidgetStoreModel.getSelected()?.meta?.id === id) {
          WidgetStoreModel.setSelected(refreshedWidget[0])
        }
      })
  }

  shareCategory(id: number, aclRule: AclRule): Promise<void> {
    /** Because sub-folders will have their permissions updated, we refresh all categories */
    const reloadAllCategories = () => this.reloadCategoryByFilters()

    return this.appContext.authModule
      .getApi()
      .doAction<number, { aclRule }, {}>(DATA_VIEW_CATEGORIES, id, 'share', {
        aclRule,
      })
      .then(reloadAllCategories)
  }
}
