import { SortDirectionType } from 'tracktik-sdk/lib/common/entity-collection'

import { CollectionQuery } from '@/tt-widget-components'
import { FilterOperatorType } from '@/tt-widget-factory'
import { Resources } from '@/tt-entity-design/src/types'
import { WidgetCollectionSearchProviderInterface } from '@/tt-widget-factory/services/widget-collections/types'
import { WidgetName } from '@/tt-widget-components/lib/names'

import { CUSTOM_WIDGET_PROVIDER_NAME } from '.'
import { DataViewsAttributes, GrantSource } from './types'
import { getQueryWidgetFields } from './data-view-provider-query'

const enforceArray = (val: unknown | unknown[]): unknown[] =>
  Array.isArray(val) ? val : [val]

export const getCustomWidgetQuery = (
  searchProvider: WidgetCollectionSearchProviderInterface,
  userId: number,
): CollectionQuery => {
  const query: CollectionQuery = {
    resource: Resources.DATA_VIEWS,
    fields: getQueryWidgetFields(),
    filters: [],
    sort: [
      {
        attribute: DataViewsAttributes.updatedOn,
        direction: SortDirectionType.DESC,
      },
    ],
  }

  if (searchProvider.categoryId) {
    const categoryIds = enforceArray(searchProvider.categoryId) as string[]
    categoryIds.forEach((categoryId) => {
      const formattedId = categoryId.split('|').pop()
      if (categoryId === `${CUSTOM_WIDGET_PROVIDER_NAME}-category|`) {
        query.filters.push({
          attribute: DataViewsAttributes.category,
          operator: FilterOperatorType.ISNULL,
        })
        return
      }
      if (formattedId) {
        query.filters.push({
          attribute: DataViewsAttributes.category,
          operator: FilterOperatorType.EQUAL,
          value: formattedId,
        })
        return
      }
    })
  }

  if (searchProvider.categoryIdExcludes) {
    const categoryIdsExcludes = enforceArray(
      searchProvider.categoryIdExcludes,
    ) as string[]
    categoryIdsExcludes.forEach((categoryIdExcludes) => {
      const formattedId = categoryIdExcludes.split('|').pop()
      if (formattedId) {
        query.filters.push({
          attribute: DataViewsAttributes.category,
          operator: FilterOperatorType.NOT,
          value: formattedId,
        })
        return
      }
      if (
        categoryIdExcludes.includes(`${CUSTOM_WIDGET_PROVIDER_NAME}-category|`)
      ) {
        query.filters.push({
          attribute: DataViewsAttributes.category,
          operator: FilterOperatorType.ISNOTNULL,
        })
        return
      }
    })
  }

  if (searchProvider.searchQuery) {
    query.search = searchProvider.searchQuery
  }

  if (searchProvider.typeFilter) {
    const typeFilters = enforceArray(searchProvider.typeFilter) as WidgetName[]
    typeFilters.forEach((typeFilter) => {
      query.filters.push({
        attribute: DataViewsAttributes.widgetType,
        operator: FilterOperatorType.EQUAL,
        value: typeFilter,
      })
    })
  }

  if (searchProvider.typeFilterExclude) {
    const typeFiltersExclude = enforceArray(
      searchProvider.typeFilterExclude,
    ) as string[]
    typeFiltersExclude.forEach((typeFilterExclude) => {
      query.filters.push({
        attribute: DataViewsAttributes.widgetType,
        operator: FilterOperatorType.NOT,
        value: typeFilterExclude,
      })
    })
  }

  if (searchProvider.sortBy) {
    query.sort = [
      {
        attribute: searchProvider.sortBy,
        direction: SortDirectionType.DESC,
      },
    ]
  }

  if (searchProvider.ownedByMe) {
    query.filters.push({
      attribute: DataViewsAttributes.ownedBy,
      operator: FilterOperatorType.EQUAL,
      value: userId,
    })
  }

  if (searchProvider.sharedWithMe) {
    query.filters.push({
      attribute: DataViewsAttributes.grantSource,
      operator: FilterOperatorType.EQUAL,
      value: GrantSource.This,
    })
    query.filters.push({
      attribute: DataViewsAttributes.ownedBy,
      operator: FilterOperatorType.NOT,
      value: userId,
    })
  }

  if (searchProvider.pagination) {
    query.limit = searchProvider.pagination.limit
    query.offset = searchProvider.pagination.offset
  }

  return query
}
